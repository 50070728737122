import React from "react";
import OracleFinancialsCloud from "../images/oracle-financials-cloud.jpg";
import OracleHCMCloud from "../images/oracle-hcm-cloud.jpg";
import OracleSCMCloud from "../images/oracle-scm-cloud.jpg";
import OracleCXCloud from "../images/oracle-cxcloud.jpg";
import OracleBICloud from "../images/oracle-biepm-cloud.jpg";
import ICloudReady from "../images/icloudready-cloud.jpg";

import CloudStorage from "../images/cloud-storage.svg";
import Click from "../images/click.svg";
import Checklist from "../images/verify.svg";

import BusinessAndFinance from "../images/business-and-finance-2.svg";
import LandingPage from "../images/landing-page.svg";
import Employees from "../images/employees.svg";

import Settings from "../images/settings.svg";
import Leaf from "../images/leaf.svg";
import Worker from "../images/worker.svg";

import Dashboard from "../images/dashboard.svg";
import DataTransfer from "../images/data-transfer.svg";
import CustomerService from "../images/customer-service.svg";

import PieChart from "../images/pie-chart.svg";
import ExportImage from "../images/export.svg";
import Report from "../images/report.svg";

export const CLOUD_SERVICES = [
    {
        img: OracleFinancialsCloud,
        title: (
            <p>Oracle Financials <span>Cloud</span></p>
        )       
    },
    {
        img: OracleHCMCloud,
        title: (
            <p>Oracle HCM <span>Cloud</span></p>
        )       
    },
    {
        img: OracleSCMCloud,
        title: (
            <p>Oracle SCM <span>Cloud</span></p>
        )       
    },
    {
        img: OracleCXCloud,
        title: (
            <p>Oracle CX <span>Cloud</span></p>
        )       
    },
    {
        img: OracleBICloud,
        title: (
            <p>Oracle BI/EPM <span>Cloud</span></p>
        )       
    },
    {
        img: ICloudReady,
        title: (
            <p>Icloudready <span>Cloud</span></p>
        )       
    }
];

export const FEATURES = [
    {
        img: CloudStorage,
        title: (<p>Accelerated <strong>Implementations</strong></p>),
        description: "Our knowledge and experience means we can accelerate your projects, providing you with a faster time to value with minimal risk."
    },
    {
        img: Click,
        title: (
            <p>Save more with One-Step
                <strong style={{display: "block"}}>Shopping Experience</strong>
            </p>
        ),
        description: "We offer a full range of services, from requirements gathering, through solution design and implementation to ongoing training and support, we do it all and in one agreement!."
    },
    {
        img: Checklist,
        title: (
            <p>
                <strong>Delivering Experience</strong>
            </p>
        ),
        description: "Passionate about being the best and to always delivering beyond expectations, earning trust and building long-lasting relationships with our clients."
    }
];

export const APPS = [
    {
        name: "ERP",
        description: "Simplify, Standardise and Save with Oracle ERP Cloud!.",
        href: "/erp"
    },
    {
        name: "HCM",
        description: "Modernise HR and get the Benefits of the Cloud.",
        href: "/hcm"
    },
    {
        name: "CX",
        description: "Simplify, Innovate and Deliver with Smarter CX.",
        href: "/cx"
    },
    {
        name: "BI & EPM",
        description: "Gain agility and insights to outperform the market with Oracle BI & EPM cloud.",
        href: "/epm"
    }
];

export const ERP_FEATURES = [
    {
        img: BusinessAndFinance,
        description: (
            <p>Improved <strong>Performance</strong></p>
        )
    },
    {
        img: LandingPage,
        description: (
            <p>Optimized Oracle <strong>Applications</strong></p>
        )
    },
    {
        img: Employees,
        description: (
            <p>Deep Oracle <strong>Expertise</strong></p>
        )
    }
];

export const HCM_FEATURES = [
    {
        img: Settings,
        description: (
            <p>Optimize Workforce Management</p>
        )
    },
    {
        img: Leaf,
        description: (
            <p>Transform your workplace for the future</p>
        )
    },
    {
        img: Worker,
        description: (
            <p>Rethink your HR operations</p>
        )
    }
];

export const CX_FEATURES = [
    {
        img: PieChart,
        description: (
            <p>Impower your sales and service teams with the best technology</p>
        )
    },
    {
        img: ExportImage,
        description: (
            <p>Improve understanding of your customer behavior</p>
        )
    },
    {
        img: Report,
        description: (
            <p>Deliver Native Mobile Digital Engagement</p>
        )
    }
];

export const EMP_FEATURES = [
    {
        img: CustomerService,
        description: (
            <p>Building a Better Contact Center</p>
        )
    },
    {
        img: DataTransfer,
        description: (
            <p>Immdetaily Launch Your New Customer Digital Engagment Joureny</p>
        )
    },
    {
        img: Dashboard,
        description: (
            <p>Replace your old CRM with brand new CRM</p>
        )
    }
]