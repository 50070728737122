import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import GlobalStyle from "./GlobalStyles"

import { maxWidth } from "../lib/layout"

const CenterContainer = styled.div`
  margin: 0 auto;
  padding-top: 5.5rem;
  max-width: ${maxWidth}px;
`

const Layout = ({ pathName, children }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header pathName={pathName} />
      <CenterContainer>
        <main>{children}</main>
        <Footer />
      </CenterContainer>
    </>
  )
}

export default Layout
